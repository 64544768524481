.tox-editor-header {
  padding: 0 !important;
}
.tox-tinymce {
  border-radius: 0 !important;
  border: none !important;
  font-family: Inter, "Noto Sans JP", sans-serif !important;
}

.tox-toolbar__primary {
  background-color: #e6e6e6 !important;
  margin-top: 0 !important;
  padding: 1.5rem !important;

  button {
    padding: 0 40px !important;
  }
}

.check-box-options-active {
  border-radius: 100%;
  border: 1px solid #000;
  padding: 5px;
}

.check-box-options-inactive {
  border-radius: unset;
  border: none;
  padding: 5px;
}
