:root {
  --table-alternate-background: #fafafb;
  --information-message-text: #0e566c;
  --information-message-border: #bedfe6;
  --information-message-background: #f8ffff;
  --warning-message-text: #7a4d05;
  --warning-message-border: #ccbea0;
  --warning-message-background: #fffaf3;
  --error-message-border: #ebcccc;
  --error-message-background: #fff6f6;
  --success-message-border: #a8c599;
  --success-message-background: #fcfff5;
  --error-message-text: #973937;
  --success-message-text: #1e561f;
  --blue: #2185d0;
  --gray: #767676;
  --black: #1b1c1d;
  --purple: #a333c8;
  --red: #db2828;
  --orange: #f2711c;
  --yellow: #fbbd08;
  --olive: #b5cc18;
  --green: #21ba45;
  --teal: #00b5ad;
  --violet: #6435c9;
  --pink: #e03997;
  --brown: #a5673f;
  --grey: #767676;

  // Background color
  --primary-bg: #ffffff;
  --secondary-bg: #fafafb;

  --primary-color: #009be0;
  --primary-text-color: #171717;
  --primary-border-color: #d4d4d4;

  // Text color
  --primary-text: #000000de;
  --secondary-text: #00000066;
  --confirmed: #2185d0;
  --defect: #db2828;
  --treated: #21ba45;
  --blue-100: #2d9bf0;
}
