.moreColorsModal {
  .chrome-picker {
    width: 300px !important;
  }
  .sketch-picker {
    width: 400px !important;
    box-shadow: unset !important;
    border-radius: unset !important;
    .flexbox-fix:nth-child(4) {
      display: none !important;
    }
    .flexbox-fix {
      div:nth-child(1) {
        cursor: pointer;
      }
    }
  }
}
