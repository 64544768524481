.selected-component {
  z-index: 2;
}

.react-draggable-dragging {
  z-index: 1;
}

.selected-td {
  box-shadow: 0 0 0 2px #1795fe inset !important;
}


.blackboard-image {
  @media print {
    max-height: 99%
  }
}

.chakra-modal__content-container {
  padding: 1.5rem;
}

.moveable-control {
  border-color: #1795fe !important;
  background-color: #fff !important;
  width: 8px !important;
  height: 8px !important;
  margin-top: -4px !important;
  margin-left: -4px !important;
  border-width: 1px !important;
}

.draggable-controls {
  opacity: 0;
  pointer-events: none;

  &.active {
    pointer-events: auto;
    opacity: 1;
  }
}

@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.bg-white {
  background-color: #fff;
}

  .moveable-gap{
  white-space: nowrap;
}

.ScrollbarsCustom-Wrapper {
  inset: 0 !important;
}

.ScrollbarsCustom-Thumb {
  background: #d2d2d2 !important;
  border-radius: 0 !important;
}

.ScrollbarsCustom-Track {
  border-radius: 0 !important;
  width: 8px !important;
  z-index: 10;
}