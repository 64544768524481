.node-normalText {
  height: 100%;
}


.tiptap ul[data-type="taskList"] li {
  & input {
    cursor: pointer;
  }
}
.tiptap ul[data-type="taskList"] {
  list-style: none;
  margin-left: 0;
  padding: 0;
}

.node-imageUploaded,
.node-image {
  height: 100%;
  width: 100%;
}

.node-keynote {
  height: 100%;
}

.tiptap-link {
  text-decoration: underline;
  cursor: pointer;
  color: var(--primary-color);
}

.tiptap.ProseMirror {
  cursor: default;
  pointer-events: none;
}

.node-normalText.has-focus > div {
  z-index: 1000;
  background-color: white !important;
  border: 2px solid #2d9bf0 !important;
}

.ProseMirror[contenteditable="true"]:has(.node-normalText.has-focus) {
  z-index: 1;
}

.ProseMirror[contenteditable="true"]:has(.node-normalText > div:hover) {
  z-index: 1;
}

.ProseMirror[contenteditable="true"] {
  .node-normalText > div {
    &:hover {
      z-index: 999;
      background-color: #c3dee5;
    }
  }
  .node-normalText:not(.has-focus) > div:first-child, .wrapper-image-node {
    background:  #E0C2001A !important
  }
  .node-normalText:not(.has-focus) > div:first-child:hover, .wrapper-image-node:hover {
    background: rgba(0, 155, 224, 0.12) !important;
    border: 2px solid rgba(0, 155, 224, 1) !important;
  }
}
.wrapper-image-node:hover {
  .image-thumb-icon {
    background: rgba(10, 142, 230, 0.6);
  }
}
