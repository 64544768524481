.bb-select {
  height: 100%;
  width: 100%;

  .chakra-select__wrapper {
    height: 100% !important;

    select {
      height: 100%;
      border: none !important;
    }
  }
}
