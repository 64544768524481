.pre-pac {
  .diagonalFalling {
    background: linear-gradient(
      to right top,
      #ffffff 0%,
      #ffffff 49.9%,
      #000000 50%,
      #000000 51%,
      #ffffff 51.1%,
      #ffffff 100%
    );
  }
  .tableHeader {
    .chakra-table {
      tbody {
        tr {
          height: 64px;
          td:nth-child(1) {
            border-right: 3px solid black;
            border-bottom: 3px solid black;
          }
        }
      }
    }
  }
  .tableContent {
    .chakra-table {
      thead {
        .css-fzalg2 {
          height: 55px;
        }
      }
      tbody {
        tr {
          height: 55px;
          td {
            padding: 3px 5px;
          }
        }
      }
    }
  }
}
