.input-datePicker {
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: block;
    width: 100%;
    height: 38px;
    background: inherit;
  }
  .react-datepicker__input-container input {
    padding: var(--chakra-space-4);
  }
  .react-datepicker-wrapper {
    border-radius: var(--chakra-radii-md);
    border: 1px solid;
    border-color: inherit;
  }
  .react-datepicker__day {
    width: 2.2rem;
    line-height: 2.2rem;
  }
  .react-datepicker-popper {
    z-index: 10;
  }
}
