// multi-images
.slider-image {
  .slick-prev,
  .slick-next {
    margin-top: 18px;
    position: unset;
  }
  .slick-list {
    width: 100% !important;
  }
}
