.toolbar {
  .t16lpgj {
    display: flex;
    background: initial;
    border: 0px;
    border-radius: 4px;
    box-shadow: initial;
    z-index: 2;
    box-sizing: initial;
    .bi09khh {
      margin-right: 12px;
      .bc4rxid {
        background: initial;
        width: fit-content;
        height: fit-content;
        svg {
          fill: #171717;
          @media screen and (max-width: 960px) {
            height: 20px;
            width: 20px;
          }
        }
      }

      .akzb7t5 {
        svg {
          fill: #009be0;
        }
      }

      .bc4rxid:hover,
      .bc4rxid:focus {
        background: #fff;
      }
      button {
        padding-top: 0px;
        @media screen and (min-width: 667px) and (max-width: 960px) {
          width: 30px;
        }
      }
    }
  }
}
.editcomment {
  .DraftEditor-root {
    margin-top: -8px;
    max-width: 45.4rem;
    width: 100%;
    .DraftEditor-editorContainer {
      margin-left: 2rem;
      .public-DraftStyleDefault-ul {
        margin-left: 2rem;
      }
    }
    .public-DraftEditorPlaceholder-inner {
      position: absolute;
      margin-left: 1.8rem;
      color: #ccc;
    }
  }
}
.slider-custom {
  .slick-slider {
    .slick-prev {
      z-index: 300;
      left: -26px;
      &::before {
        color: #bebebe;
        font-size: 24px;
      }
    }
    .slick-next {
      z-index: 300;
      right: -20px;

      &::before {
        color: #bebebe;
        font-size: 24px;
      }
    }
    .slick-list {
      .product-images-slider-thumbs-wrapper {
        position: relative;
        margin-right: 2px;
      }
      .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        margin-left: 0;
      }
    }
  }
}
.block-view {
  a {
    color: blue;
    text-decoration: revert;
  }
}
