.date-picker-calendar {
  z-index: 99 !important;
  zoom: 1.5;
}

.calendar-zIndex {
  z-index: 99 !important;
}

.calendar-date-selected {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected {
  background-color: #fff !important;
  color: black !important;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #f0f0f0 !important;
}

.calendar-time-input {
  width: 45px !important;
  padding: 4px;
  padding-right: 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.calendar-popper {
  display: none;
  transform: translate3d(0px, -40px, 0px);
}

.time-picker {
  width: 100%;
  height: 4.4rem;
  padding-left: 8px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
}

.time-picker:focus {
  border-color: #3182ce;
  box-shadow: 0 0 0 1px #3182ce;
}
