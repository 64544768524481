:root {
  --app-height: 100vh;
  --header-height: 5.4rem;
  --sub-header-height: 5.6rem;
  --default-header-height: 4rem;
  --default-header-height-forge-viewer: 6.4rem;
  --content-height: calc(var(--app-height) - var(--header-height));
  --popper-arrow-size-half: calc(var(--popper-arrow-size, 8px) / 2);
  --popper-arrow-offset: calc(var(--popper-arrow-size-half) * -1);
}
