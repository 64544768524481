html {
  font-size: 9px;

  @media screen and (min-width: 768px) {
    font-size: 8px;
  }

  @media screen and (min-width: 960px) {
    font-size: 9px;
  }

  @media screen and (min-width: 1330px) {
    font-size: 10px;
  }
}

// hide popup error on dev
body > #webpack-dev-server-client-overlay {
  display: none !important;
}

html,
body {
  overflow: hidden;
  position: relative;
  overscroll-behavior-x: none;
}

* {
  /* Only allow scroll gestures */
  touch-action: pan-x pan-y;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  outline-color: transparent;
  outline-style: none;
  outline-width: 0;
  word-break: break-all;
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.text {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden !important;
  white-space: nowrap;
}

.button {
  &:active {
    opacity: 0.7;
  }

  cursor: pointer;
}

.hide-scroll-bar {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.largest-z-index {
  z-index: 999999 !important;
}

input[type="password"]:not(:placeholder-shown) {
  font-family: FontPw, sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.disable-mouse-event {
  pointer-events: none;
  opacity: 0.5;
}

.scroll-bar-mini {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px !important;
  }

  &::-webkit-scrollbar:horizontal {
    height: 4px !important;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 9px;
}

::-webkit-scrollbar:horizontal {
  height: 9px;
}

::-webkit-scrollbar-thumb {
  background-color: #d2d2d2;
  border-radius: 0px;
}

::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #e5e5e5;
}

::placeholder {
  /* Most modern browsers support this now. */
  opacity: 0.5;
}

.hide-placeholder-option {
  option:first-of-type {
    display: none;
  }
}

#container-chat-inspection-item::-webkit-scrollbar,
.family-instance-list .ReactVirtualized__List::-webkit-scrollbar {
  width: 5px !important;
}

.selected-cell {
  color: #1a44a9;
  font-weight: bold;
}

.selected-cell::before {
  color: orange;
  font-size: 0.75rem;
  content: "● ";
  line-height: 100%;
  vertical-align: middle;
}

.box-scroll-bar {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 8px;
  }
}

.text-area-scroll-bar {
  &::-webkit-scrollbar {
    width: 3px;
  }
}

.cell-attach-link {
  text-decoration: underline;
  color: #1155cc;
}

.resize-column {
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 5px;
  height: 100%;
  right: -2px;
  top: 0;
  border-right: 2px solid transparent;
  z-index: 999;
}

.resize-row {
  display: block;
  position: absolute;
  cursor: row-resize;
  width: 100%;
  height: 5px;
  bottom: -2px;
  right: 0;
  border-bottom: 2px solid transparent;
  z-index: 999;
}

.limit-1-rows,
.limit-2-rows,
.limit-3-rows,
.limit-4-rows {
  overflow: hidden;
  text-overflow: ellipsis;
}

.limit-2-rows,
.limit-3-rows,
.limit-4-rows {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal !important;
}

.limit-1-rows {
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.limit-2-rows {
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.limit-3-rows {
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.limit-4-rows {
  -webkit-line-clamp: 4;
  line-clamp: 4;
}

.chakra-form__label {
  margin-right: 0px !important;
}

.task-slick {
  .slick-slider {
    display: flex;
    align-items: center;
  }

  .slick-prev::before,
  .slick-next::before {
    color: #ccc;
  }
}

#preview-document-category-slidefade {
  transform: none !important;
}

.toolbarComment {
  button[type="button"] {
    border-radius: 6px;
    min-height: 32px;
    padding-inline: 1px;

    background: transparent !important;

    &:hover {
      svg {
        fill: #38bdf8 !important;
      }
    }

    &:active {
      box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px inset,
        #009be0 0px 0px 0px 2px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px;

      svg {
        fill: #009be0 !important;
      }
    }
  }
}

.hidden {
  display: none !important;
}

.custom-scrollbar {
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #a3a3a3;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }
}
